import React, { useState } from 'react';
import { Header } from './Header';
import { ClickableMap } from './ClickableMap';
import { Figures } from './Figures';
import { LayoutCentered, Layout2Column } from './Layouts';
import { Regions } from './../services/regions';

export const Storybook: React.FC<any> = () => {
  const nop = (value: String) => { console.log("clicked on", value)};
  return (
    <div className="calc">
      <h2>STORYBOOK: AREAS</h2>
      <div style={{ display: 'flex' }}>
        {Regions.map(r => <div key={r.id}>{r.id}<br /><ClickableMap value={r.id} onChange={nop} /></div>)}
      </div>
    </div>
  );
}

export const LandingPage: React.FC<any> = () => {
  const myRef = React.useRef<HTMLHeadingElement>(null);

  const [area, setArea] = useState<string>('');
  const onClick = (value: string) => {
    setArea(value);
    setTimeout(() => {
      if (!myRef || !myRef.current) return;
      // if (!window || window.innerWidth > 800) return;
      // myRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 50);
  }
  return (
    <div>
      
      <LayoutCentered>
        <Layout2Column>
          <div className='left'>
            <ClickableMap value={area} onChange={onClick} />
          </div>
          <div className='right'>
            <Header full={false} />
            {area ? <Figures {...{
                area, region: Regions.find(r => (r.id === area))
              }} /> : 
              <Header full={true} />
            }
          </div>
        </Layout2Column>
        <div className="bottom" ref={myRef}></div>
      </LayoutCentered>
    </div>
  );
}

