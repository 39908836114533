import { IRegion } from './types'

// Excel helper to generate:
// ="{id: '', name: '" & A5 & "', total: "&B5 &", avgWealth: "&C5 &", cases: "&D5 &", typical: "&E5 &"},"

export const Regions: Array<IRegion> = [
    {
        id: 'east-anglia',
        name: 'Eastern',
        total: 99205425,
        avgWealth: 309491,
        cases: 1705,
        typical: 58185,
        averageLtv: 19,
        averageInterest: 4.58,
        averageReferral: 2068.90,
        paragraph: 'The lending demand in the Eastern region increased with a total of £269,989,851 released in equity in 2022'
    },
    {
        id: 'east-midlands',
        name: 'East Midlands',
        total: 148586624,
        avgWealth: 297014,
        cases: 2464,
        typical: 60352,
        averageLtv: 20,
        averageInterest: 4.6,
        averageReferral: 1832.23,
        paragraph: 'The lending demand in the East Midlands region increased with a total of £348,426,509 released in equity in 2022 '
    },
    {
        id: 'london',
        name: 'London',
        total: 404567904,
        avgWealth: 716779,
        cases: 2652,
        typical: 152552,
        averageLtv: 21,
        averageInterest: 4.35,
        averageReferral: 3900.39,
        paragraph: 'The lending demand in the London region increased with a total of £1,015,501,006 released in equity in 2022 '
    },
    {
        id: 'north-east',
        name: 'North East',
        total: 44817678,
        avgWealth: 219799,
        cases: 918,
        typical: 48821,
        averageLtv: 22,
        averageInterest: 4.65,
        averageReferral: 1589.20,
        paragraph: 'The lending demand in the North East region increased with a total of £132,465,386 released in equity in 2022'
    },
    {
        id: 'north-west',
        name: 'North West',
        total: 169996464,
        avgWealth: 262559,
        cases: 3009,
        typical: 56496,
        averageLtv: 22,
        averageInterest: 4.72,
        averageReferral: 1540.17,
        paragraph: 'The lending demand in the North West region increased with a total of £418,010,407 released in equity in 2022'
    },
    {
        id: 'northern-ireland',
        name: 'Northern Ireland',
        total: 15226270,
        avgWealth: 216201,
        cases: 310,
        typical: 49117,
        averageLtv: 23,
        averageInterest: 4.81,
        averageReferral: 1492.08,
        paragraph: 'The lending demand in the Northern Ireland region has remained strong with a total of £34,735,514 released in equity in 2022'
    },
    {
        id: 'scotland',
        name: 'Scotland',
        total: 122347334,
        avgWealth: 243988,
        cases: 2074,
        typical: 58991,
        averageLtv: 24,
        averageInterest: 4.5,
        averageReferral: 1373.81,
        paragraph: 'The lending demand in the Scotland region increased with a total of £214,679,035 released in equity in 2022'
    },
    {
        id: 'south-east',
        name: 'South East',
        total: 500635193,
        avgWealth: 450706,
        cases: 6103,
        typical: 82031,
        averageLtv: 18,
        averageInterest: 4.53,
        averageReferral: 2606.25,
        paragraph: 'The lending demand in the South East region increased with a total of £1,552,914,224 released in equity in 2022'
    },
    {
        id: 'south-west',
        name: 'South West',
        total: 250000317,
        avgWealth: 403576,
        cases: 3213,
        typical: 77809,
        averageLtv: 19,
        averageInterest: 4.52,
        averageReferral: 2515.92,
        paragraph: 'The lending demand in the South West region increased with a total of £699,887,467 released in equity in 2022'
    },
    {
        id: 'wales',
        name: 'Wales',
        total: 89612987,
        avgWealth: 271381,
        cases: 1579,
        typical: 56753,
        averageLtv: 21,
        averageInterest: 4.65,
        averageReferral: 1704.97,
        paragraph: 'The lending demand in the Wales region increased with a total of £217,833,230 released in equity in 2022'
    },
    {
        id: 'west',
        name: 'West Midlands',
        total: 155378022,
        avgWealth: 291364,
        cases: 2426,
        typical: 64047,
        averageLtv: 22,
        averageInterest: 4.65,
        averageReferral: 2047.82,
        paragraph: 'The lending demand in the West Midlands region increased with a total of £394,461,123 released in equity in 2022'
    },
    {
        id: 'yorkshire',
        name: 'Yorkshire & Humberside',
        total: 126407292,
        avgWealth: 249687,
        cases: 2211,
        typical: 57172,
        averageLtv: 23,
        averageInterest: 4.66,
        averageReferral: 1604.30,
        paragraph: 'The lending demand in the Yorkshire and Humberside region increased with a total of £276,499,175 released in equity in 2022'
    }
];

// prepending region with "the", i.e. "in the North East"
export const the = (regionId: string): string => {
    const noThe = ['scotland', 'yorkshire', 'wales', 'london', 'east-anglia', 'northern-ireland'];
    if (noThe.indexOf(regionId) !== -1) return '';
    return 'the';
}
