import React from 'react';
import styled from 'styled-components';

const Btn = styled.button`
    width: 100%;
    cursor: pointer;
    outline: none !important;
    overflow: hidden;

    background: white url(images/btn-chevron-right-orange.png) no-repeat;
    background-position: right 0px top;
    color: black;
    border: none;
    height: 48px;
    line-height: 48px;
    text-align: left;
    font-weight: bold;
    font-size: 14px;
 
    margin-bottom: 20px;
    box-shadow: 0px 2px 10px #ccc;
    
    position: relative;
    span { display: block; line-height: 18px; font-size: 14px; padding-left: 50px; padding-right: 30px; text-overflow: ellipsis; word-break: break-all; }
    img { top:10px; left: 10px; height: 26px; position: absolute; }

    @media screen and (max-width: 480px) {
        span { font-size: 12px; }
    }
    @media screen and (max-width: 420px) {
        span { font-size: 10px; }
    }
    @media screen and (max-width: 360px) {
        span { font-size: 9px; }
    }
`;

export const AdvancedTrainingBtn: React.FC<any> = () => {
    const link = 'https://www.keypartnerships.co.uk/resources/marketing-support/';
    return (
        <Btn onClick={() => (window.open(link))}>
            <img src='images/icon-tv.png' alt='' />
            <span>Use our resources to help you bring equity release<br/>into your business</span>
        </Btn>
    )
}

export const DownloadReportBtn: React.FC<any> = () => {
    const link = 'https://www.keypartnerships.co.uk/downloads/market-monitor-2023-full-year-report/?tmstv=1721654405'
    return (
        <Btn onClick={() => (window.open(link))}>
            <img src='images/icon-webinar.png' alt='' />
            <span>Download our latest Market Monitor<br /> report for more insights</span>
        </Btn>
    )
}
