import React from 'react';
import styled from 'styled-components';
import { the } from './../services/regions';
import { IKV, IAreaRegion } from './../services/types';
import { toPounds } from './../services/formats';
import { AdvancedTrainingBtn, DownloadReportBtn } from './CTA';

const OurIntelligence: React.FC<any> = (props: any) => {
    return props.region.paragraph ? (
        <p>{props.region.paragraph}</p>
    ): (
        <p>
            Our intelligence shows there has been a lot of equity release
            activity in {the(props.region.id)} {props.region.name} in the last year. Report to advanced
            training for help locating targets within your area.
        </p>
    );
}

const LayoutWithImage = styled.div`
    margin-top: 5px;
    display: flex;
    width: 100%;
    
    .l { flex: 1; }
    img { float: right; margin-left: 20px; }
    .hd {
        font-size: 14px;
        font-weight: bold;
        color: #f15d33;
        padding: 5px 20px;
    }
    p { font-size: 14px; margin-top: 5px; }

    @media (min-width: 832px) {
        .hd { padding: 5px 0px; }
    }
`

const AdvancedTraining: React.FC<any> = () => (
    <LayoutWithImage>
        <div className="l">
            <div className='hd'>Unlock your clients’ financial possibilities with equity release referrals</div>
            <p>
                Find out how referring your clients to Key Partnerships for specialist equity release advice
                can give you a complementary solution to your existing capabilities,
                plus, create a valuable income stream for your business.
            </p>
            <h3 style={{ color: "#132220", fontWeight: 'bold' }} >
                Call 0800 138 1663 or email <a style={{ color: "#132220" }} href="mailto:refer@keypartnerships.co.uk">refer@keypartnerships.co.uk</a> to learn more.
            </h3>
        </div>
    </LayoutWithImage>
);

const FiguresWrapper = styled.div`
    .title {
        font-size: 18px;
        color: #fff;
        background: #162730;
        padding: 10px 15px;
    }
    table { width: 100%; padding: 20px 15px; background: white; }
    table tbody th { text-align: left; font-size: 14px; line-height: 20px; width: 60%; }
    table tbody td { text-align: left; font-size: 14px; line-height: 20px; }
`;

export const Figures: React.FC<any> = (props: IAreaRegion) => {
    const { region } = props;
    if (!region) return null;

    const fp: Array<IKV> = [
        {key: 'Number of cases:', value: region.cases},
        {key: 'Total lending:', value: toPounds(region.total)},
        {key: 'Average housing wealth:', value: toPounds(region.avgWealth)},
        {key: 'Average release / LTV:', value: toPounds(region.typical) + ' / ' + region.averageLtv + "%"},
        // {key: 'Average interest rate:', value: region.averageInterest.toFixed(2) + "%"},
        // {key: 'Average referral fee:', value: toPounds(region.averageReferral.toFixed(2))}
    ];
    return (
        <FiguresWrapper>
            <div className='title'>Equity release lending in {region.name} 2023</div>
            <table cellSpacing={0} cellPadding={3}>
                <tbody>
                    {fp.map(kv => (
                        <tr key={kv.key}><th>{kv.key}</th><td>{kv.value}</td></tr>
                    ))}
                </tbody>
            </table>
            {/*<OurIntelligence region={region}/>*/}
            <AdvancedTraining />
            <AdvancedTrainingBtn />
            <DownloadReportBtn />
            <p><small>Data source: Key Market Monitor Full Year 2023</small></p>
        </FiguresWrapper>
    )
};
