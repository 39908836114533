import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
    h1 { 
        padding-top: 20px;
        text-align: left;
        color: #f15d33;
        font-weight: bold;
        font-size: 1.45rem;
        margin-bottom: 30px;
    }
    p { 
        margin: 0px auto;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 0px;
        max-width: 740px;
        line-height: 1.5em;
        text-align: left;
        font-size: 1rem !important;
        color: #666; 
    }
`;
export const Header: React.FC<any> = (props: any) => {

    return (
        <Wrap>
            {props.full ? (
                <div>
                    <p>
                        If you have clients aged 55 and over, they could be sitting on a big opportunity to unlock a better financial future for themselves and their families – and in turn help grow your own business.
                    </p>
                    <p>
                        Each year, equity release helps tens of thousands of homeowners tap into the wealth within their homes.
                    </p>
                    <p>
                        <strong>
                            Click on your region of the map opposite to see the latest statistics for your area.
                        </strong>
                    </p>
                    <p>
                        For more help to see how you and your clients could benefit from referring to Key Partnerships, call us on 0800 138 1663
                        or email <a style={{ color: "#f15d33" }} href="mailto:refer@keypartnerships.co.uk">refer@keypartnerships.co.uk</a>
                    </p>
                </div>
            ) : <h1>See the equity release opportunity in your region</h1>}
        </Wrap>
    );
  }

