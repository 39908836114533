import React from 'react';
import styled from 'styled-components';

const Layout2ColumnDiv = styled.div`
    .left > div { text-align: center; }
    svg { width: 100%; }
    p { padding: 20px; }
    @media (min-width: 832px) {
        display: flex;
        .left { width: 50%; padding-top: 20px; }
        .right { width: 50%; }
        p { padding: 0px; }
    }
`;

export const Layout2Column: React.FC<any> = (props: any) => {
    return (<Layout2ColumnDiv>{props.children}</Layout2ColumnDiv>)
};

const LayoutCenteredDiv = styled.div`
    margin: 0px auto;
    padding-bottom: 20px;
    max-width: 960px;
`;

export const LayoutCentered: React.FC<any> = (props: any) => {
    return (<LayoutCenteredDiv>{props.children}</LayoutCenteredDiv>)
};
